.sub-heading{
  font-size: 1.8rem;
  letter-spacing: .4rem;
  text-transform: uppercase;
  opacity: .45;
}

.section-title{
  font-size: 3.5rem;
  text-transform: uppercase;
  color: white;
  margin: 1rem 0;

}
