.logo{
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    &-name{
        margin-right: 10px;
            color: #ffffff;

    }

    .line{
        margin-top: 10px;
    }

}

