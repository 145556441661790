.social-container{
    height: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
    mix-blend-mode: difference;
    z-index: 553;

    @media (max-width:968px) {
        &{
            
            display: none;
        }
    }


    svg{
        cursor: pointer;
        width: 2rem;
        path{
            fill: white;
            transition: all .1s ease-in-out;

        }
        &:hover{
            path{
                opacity: .7;                
            }
        }
    }

}
.fb-icon{
    svg{
        width: 1.2rem;
    }
    
}