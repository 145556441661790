.loader-overlay{
    height: 100vh;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader{

    display: inline-block;
    height: 50px;
    width: 50px;
    border: 3px solid rgba(195, 195, 195, 0.6);
    border-radius: 50%;
    border-top-color: #636767;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    @keyframes spin {
        to {
        -webkit-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        to {
        -webkit-transform: rotate(360deg);
        }
    }

}