.header-container{
    padding:25px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: 0;
    position: fixed;
    left: 0;
    mix-blend-mode: difference;
    z-index: 33;
    
    @media (max-width:965px) {
        position: absolute;
        padding:25px;
        
    }

}