
.project{

    
    &-landing{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: 86vh;
        width: 100vw;

        background-color: #EFEDF5;
        position: relative;
        overflow: hidden;
        &>*{
            z-index: 11;

        }
    }
    &-title{
        margin-top: 15rem;
        margin-bottom: 4rem;
        width: 50%;
        font-size: 5.5rem;
        text-align: center;
        text-transform: capitalize;
    }

    .detail-wave{
        position: absolute;
        top: -50%;
        width: 100%;
        z-index: 1;
        transform-origin: center;
        transform: rotate(180deg);
    }
   
}