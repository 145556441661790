.about {
  background-color: #191a1e;
  color: white;
  padding: 10rem 20rem 30rem 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;

  &-text {
    margin: 5rem 0;
    font-size: 1.7rem;
    width: 70%;
    line-height: 30px;
    color: rgb(255, 255, 255);

    @media (max-width: 768px) {
      & {
        width: 90%;
        margin-bottom: 6rem;
      }
    }

    span {
      font-weight: bolder;
      color: #a58861;
    }
  }

  &-abilities {
    .ability-item {
      font-size: 2rem;
      width: max-content;
      margin: 0.6rem 0;
      font-weight: 300;
    }
    display: flex;
    .ability-title {
      color: #a58861;
      text-transform: uppercase;
      font-size: 1.7rem;

      margin-bottom: 3rem;
    }

    & > * {
      margin: 0 3rem;
    }
  }
}

@media (max-width: 968px) {
  .about {
    flex-direction: column;
    padding: 10rem 3rem 30rem 5rem;
  }
}

.about-wave {
  position: absolute;
  bottom: -20rem;
  left: 0;
  width: 110%;
}
