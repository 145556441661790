.skills{
    background-color: white;
    padding: 7rem 15rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    &-title{
        margin: 1rem auto;
        .section-title{
            font-weight: 500;              
            color: #191A1E;
            
        }
    }
    &-cards{

        margin: 6rem 0;
        display: flex;
        justify-content: space-between;

        @media (max-width:968px) {
            &{
                flex-direction: column;
                align-items: center;
                &>*{
                    margin: 2rem 0;
                }
            }
        }
        
        .card{
            
            width: 29rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.076);
            border-radius: 5px ;
            transition: all .2s ease-in-out;
            &:hover{
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.076);

            }
            &__title{
                font-size: 2.3rem;
                width: 90%;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 2rem;
                border-bottom: 2px solid rgba(0, 0, 0, 0.103);
                padding: 2rem;
            }
            
            &__items{
                font-size: 1.9rem;
                margin-bottom: 3rem;
                

            }

            &__item{
                margin: 1.3rem 0;
            }
        }


    
    }



}