.menu{
    position: fixed;
    top: -120%;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #191A1E;
    overflow-y: hidden;
    color: white;
    z-index: 33;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4rem;
    transition: all .5s cubic-bezier(.21,.47,.33,.83);
    &>*{
        opacity: 0;
        transition: all .4s ease-in-out .6s;
    }
    &-active{
        top: 0%;
        &>*{
            opacity: 1;
        }

    }

    &-info{

        @media (max-width:968px) {
            &{
                
                display: none;
            }
        }

        &-title{
            color: #A58861;
            text-transform: uppercase;
            font-size: 1.7rem;

            margin-bottom: 3rem;
        }
        &-el{
            font-size: 1.5rem;
            margin: .7rem 0;
            &--copy{
                margin-top: 6rem;
                color: rgba(255, 255, 255, 0.664);
                
            }
            a{
                color: white;
                transition: all .2s ease-in-out;
                &:hover{
                    color: rgb(226, 226, 226);
                    text-decoration: underline;
                }
            }
        }
    }

}