.work{
    background-color: white;
    padding: 7rem 15rem;
    display: flex;
    flex-direction: column;
    align-content: center;

    &-title{
        margin: 1rem auto;
        .section-title{
            font-weight: 500;              
            color: #191A1E;
            
        }
    }

    &-container{
        display: grid;
        grid-template-columns: repeat( auto-fit, 40rem);
        grid-gap: 6rem;
        justify-content: center;
        align-items: center;
        justify-items: center;
        margin-top: 8rem;
    }

}