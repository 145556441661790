
.project-about-container{
    padding: 8rem 12rem;
    display: grid;
    grid-template-columns: repeat( auto-fit, 50rem);
    grid-template-rows: repeat(4,1fr);
    justify-content: center;
    
    @media (max-width:968px) {
        &{
            display: flex;
            flex-direction: column;
            padding: 7rem ;
            


        }
    }
}

.project-group{
    width: 50rem;
    &--description{
        grid-row: 1/3;
        grid-column: 1/2;
    }

    @media (max-width:968px) {
        &{
            
            margin: 1rem 0;
            width: 96%;
        }
    }



    
}

.project-group-title{
    width:  80%;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.9rem;
    letter-spacing: .2rem;
    color: rgba(0, 0, 0, 0.397);
}
.project-group-info{
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.863);
    max-width: 40rem;
    padding-bottom: 2rem;
    line-height: 2.3rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);

    a{
        &:hover{
            text-decoration: underline;
        }
    }

    

}