.contact{
    background-color: white;
    color: #191A1E;
    padding: 7rem 15rem;
    
    @media (max-width:968px) {
        &{
            padding: 2rem;
        }
    }
    .section-title{
        color: #191A1E;

    }
    &__form{
        margin-top: 6rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    &__send{
        text-transform: uppercase;
        font-size: 1.7rem;
        padding: 1.7rem 12rem;
        border: none;
        outline: none;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.027);
        color:#A58861;
        cursor: pointer;
        margin-top: 3rem;
        transition: all .2s ease-in-out;
        &:hover{
            background-color: rgba(0, 0, 0, 0.082);
            
        }
    }
    
    &__message-container{
        margin: 2rem 0;
        position: relative;
        width: 60%;
    
    }

    &__message{
        background-color: rgba(0, 0, 0, 0);
        resize: none;
        border: none;
        outline: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.692);
        width: 100%;
        padding: 1.7rem .1rem;
        font-size: 1.7rem;
        color: rgb(0, 0, 0);

        &:focus ~ .contact__label {
            top: 0rem;
            left: 0;
            font-size: 1.3rem; 
            opacity: .7;
        }


    }


}