.work{
    &__card{
        background-color: #EFEDF5;
        width: 40rem;
        color: #191A1E;
        padding: 2rem 4rem;
    }
    
    .card{
        
        &__heading{
            text-transform: uppercase;
            margin: 1rem 0;
            font-size: 2rem;
            width: 90%;
            line-height: 3rem;
            height: 6rem;
        }
        
        &__title{
            margin: 1.2rem 0;
            font-weight: bold;
        }
        
        &__description{
            height: 7rem;
            font-size: 1.5rem;
            margin: 3rem 0;
            line-height: 2.4rem;
        }

        &__footer{
            display: flex;
            justify-content: flex-end;
            margin-top: 3rem;
            padding-top: 2rem;
            font-size: 1.45rem;
            border-top: 1px solid rgba(0, 0, 0, 0.144);
        }

        &__view{
            cursor: pointer;
            padding: 1.5rem 2rem;
            background-color: rgba(0, 0, 0, 0.027); 
            border-radius: 50px;           
            transition: all .2s ease-in-out;
            &:hover{
                background-color: rgba(0, 0, 0, 0.103); 

            }
        }

    }


}