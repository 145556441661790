.project-detail{
    display: flex;
    justify-content: space-between;
    height: 60rem;
    position: relative;


    @media (max-width:968px) {
        &{
            display: flex;
            flex-direction: column;
            align-items: flex-start;


        }
    }


    &__image-container{
        height: 100%;
        width: 100%;
        background-image: linear-gradient(to left,#EFEDF5 0% ,#EFEDF5 70%,rgba(255, 255, 255, 0) 30%);
        display: flex;
        align-items: center;

        @media (max-width:968px) {
            &{
               background-image: none !important;
    
    
            }
        }


    }
    
    &__description-container{
        padding-left: 12rem;
        
        @media (max-width:968px) {
            &{
                padding-left: 5rem;
            }
        }


    }
    &__description-container h2{
        font-size: 3.5rem;
        text-transform: uppercase;
        color: #191A1E;
        padding: 1.5rem 0;
    }
    &__heading{
        margin-bottom: 5rem;
    }
    &__description{
        font-size: 1.6rem;
        line-height: 2.5rem;
        width: 45rem;
        height: 10rem;

        @media (max-width:968px) {
            &{
                
                width: 90%;
                
            }
        }
    

    }
    
    
    &__image{
        background-color: #191A1E;
        height: 40rem;
        width: 55rem;
        margin-left: 10rem;
        @media (max-width:968px) {
            &{
                
                width: 35rem;
                height: 25rem;
                margin: 0 auto;
            }
        }
    
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    
}