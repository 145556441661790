.contact{
    &__field{
        margin: 2rem 0;
        position: relative;
        height:5rem;
        display: flex;
        width: 60%;
        
    }

    &__input{
        width: 100%;
        padding: 1rem .7rem;
        background-color: rgba(255, 255, 255, 0);
        border: none;
        border-bottom: 1px solid rgba(2, 2, 2, 0.692);
        outline: none;
        color: rgb(24, 24, 24);
        margin-top: 1rem;
        font-size: 1.7rem;
        z-index: 1;
        &:focus ~ .contact__label {
            top: 0rem;
            left: 0;
            font-size: 1.3rem; 
            opacity: .7;
        }

    }


    &__label{
        position: absolute;
        top: 1.9rem;
        left: 0;
        font-family: "Lato",sans-serif;
        font-size: 1.5rem;
        transition: all .25s ease-in-out;
        z-index: 0;
        
    }
}

.shrink{
    top: 0rem;
    left: 0;
    font-size: 1.3rem;
    opacity: .7;

}