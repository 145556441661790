.project-detail-container{
    position: relative;
    margin-bottom: 15rem;
}


.project-detail__nav{
    display: flex;
    margin-top: 3rem;
    padding: 0 12rem;
    position: absolute;
    bottom: 5rem;
    
    @media (max-width:968px) {
        &{
            bottom: -8rem;
            left: 8rem ;
        }
    }

    svg{
        width: 1.4rem;
        path{
            fill:white;
        }
    }
    &>*{
        padding: 1rem 1.9rem;
        background-color: #191A1E;
        cursor: pointer;
        svg{
            transition: all .2s ease-in-out;

        }
    }
    &--1{
        transform: rotate(90deg);
        margin-right: 2rem;
        &:hover{
            svg{
                transform: translateY(4px);
            }
        }
    }
    &--2{
        transform: rotate(-90deg);
        &:hover{
            svg{
                transform: translateY(4px);
            }
        }
    }
}