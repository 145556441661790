

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  font-size: 62.5%;
  scroll-behavior: smooth;
}


@media (max-width:1280px) {
  html{
    font-size: 60.5%;

  }
  
}

@media (max-width:768px) {
  html{
    font-size: 58.5%;

  }
  
}

@media (max-width:668px) {
  html{
    font-size: 56.5%;

  }
  
}


body {
  overflow-x: hidden;
  font-size: 1.2rem;
  margin: 0;
  font-family: 'Lato',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #191A1E;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection {
  color: white;
  background: #191A1E;
}


a{
  color: #191A1E;
  text-decoration: none;
}


::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: #191A1E;
  border-radius: 5px;
}



