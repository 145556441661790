.btn{
    background-color: #191A1E;
    display: flex;
    align-items: center;
    color: white;
    padding: 1rem 3rem;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 100px;
    font-weight: 500;
    font-family: 'Lato',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 1.7rem;
    &-arrow{
        margin-left: 20px;
        margin-top: 1rem;
        svg{

            width: 2.2rem;
            margin-top: -.6rem;

            path{
                fill: rgba(255, 255, 255, 0.164);
                transition: all .2s ease-in-out;
            }
        }
    }
    
    &:hover{
        .btn-arrow{
            svg{
    
    
                path{
                    fill: rgb(255, 255, 255);
                }
            }
        }
        
    }

}

