.nav-button-container{
    cursor: pointer;
    .nav-line{
        height: 2px;
        width: 22px;
        background-color: white;
        margin: 6px 0;
        transition: all .3s;
        backface-visibility: hidden;
        transform-origin: center;
    }
    &:hover{
        .nav-line:first-child{         
            transform: translateX(-4px);
            
        }
        .nav-line:last-child{         
            transform: translateX(4px);
        }
        
    }
    
    &-active{
        .nav-line-1{         
            transform: translateY(8px) rotate(45deg);
            
            
        }
        .nav-line-2{         
            transform: rotate(-45deg);
            
        }
        
       
    }
}