.menu-item{
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    transition: all .2s ease-in-out;
    margin: 2.5rem 0;
    cursor: pointer;
    &:hover{
        color: rgb(182, 179, 179);
    }
}