.hide {
  overflow: hidden;
}
.landing {
  &-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
  }

  &-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;

    .sub-heading {
      font-size: 1.6rem;
      letter-spacing: 0.2rem;
      opacity: 0.45;
    }
    .main-heading {
      font-weight: normal;
      margin-top: 1rem;
      font-size: 5rem;
      span {
        font-weight: bolder;
        color: #a58861;
      }
    }

    @media (max-width: 768px) {
      .main-heading {
        font-size: 4rem;
      }
    }

    button {
      margin-top: 4rem;
    }
  }
}

.landing-section {
  text-align: center;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .scroll-text {
    font-size: 2rem;
  }
  .scroll-line {
    height: 90px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.265);
  }
}

.landing-wave {
  width: 100rem;
  height: 150rem;
  position: absolute;
  top: -30%;
  path {
    stroke: rgba(0, 0, 0, 0.192);
    z-index: 1;
  }
}
