.footer{
    background-color: rgba(0, 0, 0, 0.048);
    padding: 4rem;
    width: 75%;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 1.6rem;
    margin:0 auto;
    margin-top: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:968px) {
        &{
        flex-direction: column;
        align-items: center;

        &>*{
            margin: 1rem 0;
        }
    
        }
        
    }
    svg{
        cursor: pointer;
        width: 2rem;
        path{
            fill: rgb(20, 20, 20);
            transition: all .1s ease-in-out;

        }
        &:hover{
            path{
                opacity: .7;                
            }
        }
    }

    &-social{

        

        display: flex;
        justify-content: space-between;
        align-items: center;
        &>*{
            margin: 0 3rem;
        }

        
      

    }

}